<template>
  <div>
    <label class="container-switch">
      <input :class="classSelect" v-bind="$attrs" class="input-switch" type="checkbox" :checked="checked"
        :true-value="checkedValue[0]" :false-value="checkedValue[1]" @change="changePageTitle($event.target.checked)" />
      <span class="switch"></span>
      <span class="label-switch" :class="classLabel">{{ label }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "SwitchButton",
  props: {
    label: {
      type: String,
      required: true,
    },
    classSelect: {
      type: String,
      default: ""
    },
    classLabel: {
      type: String,
      default: ""
    },
    checked: {
      type: Boolean,
      required: true,
    },
    checkedValue: {
      type: Array,
      default: () => ["T", "F"]
    },
  },
  methods: {
    changePageTitle(val) {
      this.$emit('update:modelValue', val ? this.checkedValue[0] : this.checkedValue[1]);
    }
  }
};
</script>

<style scoped>
.container-switch {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.label-switch {
  margin-left: 10px;
  /* color: #1a202c; */
  /* Show an ellipsis if the text takes more than one line */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Visually hide the checkbox input */
.input-switch {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.switch {
  --switch-container-width: 50px;
  --switch-size: calc(var(--switch-container-width) / 2);
  --light-gray: #e2e8f0;
  --orange: #e60a0a;
  --gray: #cbd5e0;
  --dark-gray: #a0aec0;
  --teal: #0479cc;
  --dark-teal: #034f84;
  /* Vertically center the inner circle */
  display: flex;
  align-items: center;
  position: relative;
  height: var(--switch-size);
  flex-basis: var(--switch-container-width);
  /* Make the container element rounded */
  border-radius: var(--switch-size);
  background-color: var(--orange);
  /* In case the label gets really long, the toggle shouldn't shrink. */
  flex-shrink: 0;
  transition: background-color 0.25s ease-in-out;
}

.switch::before {
  content: "";
  position: absolute;
  /* Move a little bit the inner circle to the right */
  left: 1px;
  height: calc(var(--switch-size) - 4px);
  width: calc(var(--switch-size) - 4px);
  /* Make the inner circle fully rounded */
  border-radius: 9999px;
  background-color: white;
  border: 2px solid var(--orange);
  transition: transform 0.375s ease-in-out;
}

.input-switch:checked+.switch {
  background-color: var(--teal);
}

.input-switch:checked+.switch::before {
  border-color: var(--teal);
  /* Move the inner circle to the right */
  transform: translateX(calc(var(--switch-container-width) - var(--switch-size)));
}

.input-switch:focus+.switch::before {
  border-color: var(--gray);
}

.input-switch:focus:checked+.switch::before {
  border-color: var(--dark-teal);
}

.input-switch:disabled+.switch {
  background-color: var(--gray);
}

.input-switch:disabled+.switch::before {
  background-color: var(--dark-gray);
  border-color: var(--dark-gray);
}
</style>