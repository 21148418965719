import { defineStore } from 'pinia'
export const settingStore = defineStore({
    id: 'setting',
    state: () => ({
        "export": false
    }),
    getters: {
        "getExport": (state) => {
            return state.export;
        }
    },
    actions: {
        setExport(val) {
            this.export = val;
        },
    },
})